export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const supabaseConfig = {
    supabaseUrl: process.env.REACT_APP_SUPABASE_URL,
    supabaseKey: process.env.REACT_APP_SUPABASE_KEY,
};

export const food_types = [
    'Inconnu',
    'Africain',
    'Algérien',
    'Américain',
    'Antillais',
    'Asiatique',
    'Bistrot',
    'Boulangerie',
    'Brésilien',
    'Brunch',
    'Buffet à volonté',
    'Burger',
    'Café',
    'Cookies',
    'Coréen',
    'Crêperie',
    'Food Court',
    'Gastronomique',
    'Glacier',
    'Grec',
    'Indien',
    'Italien',
    'Japonais',
    'Kebab',
    'Libanais',
    'Marocain',
    'Mexicain',
    'Pâtisserie',
    'Poisson',
    'Portugais',
    'Poulet',
    'Québécois',
    'Snack',
    'Thaï',
    'Turque',
    'Tunisien',
    'Végétarien',
    'Viande',
];
