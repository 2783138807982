import React from 'react';
import { TableHeader } from './TableHeader';
import { TableRow } from './TableRow';
import type { ScrappedDataType } from '../types';

interface ValidatorTableProps {
    data: ScrappedDataType[];
    onInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, index: number, field: keyof ScrappedDataType) => void;
    onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, index: number, field: keyof ScrappedDataType) => void;
    onDuplicate: (index: number) => void;
    onGoogleSearch: (index: number) => void;
    onValidate: (index: number) => void;
    onValidateWithoutAdding: (index: number) => void;
}

export const ValidatorTable: React.FC<ValidatorTableProps> = ({
    data,
    onInputChange,
    onCheckboxChange,
    onDuplicate,
    onGoogleSearch,
    onValidate,
    onValidateWithoutAdding,
}) => (
    <table className="min-w-full divide-y divide-gray-100 table-auto text-xs">
        <TableHeader />
        <tbody className="bg-white">
            {data.map((row, index) => (
                <TableRow
                    key={index}
                    row={row}
                    index={index}
                    onInputChange={onInputChange}
                    onCheckboxChange={onCheckboxChange}
                    onDuplicate={onDuplicate}
                    onGoogleSearch={onGoogleSearch}
                    onValidate={onValidate}
                    onValidateWithoutAdding={onValidateWithoutAdding}
                />
            ))}
        </tbody>
    </table>
);