import { API_BASE_URL } from '../constants';

export const validatorApi = {
    async googleSearch(name: string) {
        try {
            const response = await fetch(`${API_BASE_URL}/fetch_and_add_data_and_photos`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    name,
                    food_type: 'Poisson',
                    toBeAdded: false,
                }),
            });
            return await response.json();
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    },

    async validateRecord(validationData: Record<string, any>) {
        try {
            const response = await fetch(`${API_BASE_URL}/fetch_and_add_data_and_photos`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(validationData),
            });
            return await response.json();
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    },

    async validateWithoutAdding(id: string) {
        try {
            const response = await fetch(`${API_BASE_URL}/validate_scraped_data`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id }),
            });
            return await response.json();
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }
};