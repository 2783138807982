import { validatorApi } from '../api/validatorApi';
import type { ScrappedDataType } from '../types';
import { toast } from 'sonner';

export const useValidatorActions = (data: ScrappedDataType[], setData: (data: ScrappedDataType[]) => void) => {
    const handleGoogleSearch = async (index: number) => {
        const row = data[index];
        try {
            const result = await validatorApi.googleSearch(row.name);
            
            if (result.error) {
                toast.error('An error occured', {
                    description: `Resto with name ${row.name} not found by Google Places API`,
                });
                return;
            }

            if (result.success?.includes('Resto already exist')) {
                toast.warning('Resto already exist', {
                    description: `The record already exist in the database with name ${result.data.name} and influenceur ${result.data.restoAlreadyInDatabase}`,
                });
            }

            const newData = [...data];
            newData[index] = {
                ...newData[index],
                google_name: result.data.name,
                address: result.data.formatted_address,
                latitude: result.data.latitude,
                longitude: result.data.longitude,
                places_id: result.data.place_id,
                places_rating: result.data.rating,
                places_user_ratings_total: result.data.user_ratings_total,
                places_price_level: result.data.price_level,
            };
            setData(newData);
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred during Google search');
        }
    };

    const handleValidation = async (index: number) => {
        const row = data[index];
        const validationData = {
            id: row.id,
            name: row.name,
            food_type: row.food_type,
            vlog_date: row.vlog_date,
            vlog_title: row.vlog_title,
            vlog_link: row.vlog_link,
            instagram_link: row.instagram_link,
            influencer_name: row.influencer_name,
            toBeAdded: true,
            addVlogOnExistingPlace: row.addVlogOnExistingPlace,
        };

        try {
            toast.info('Ongoing', {
                description: `The record with name ${row.name} is being registered`,
            });

            const result = await validatorApi.validateRecord(validationData);

            if (result.success?.includes('Resto already exist')) {
                toast.warning('Resto already exist', {
                    description: `The record already exist in the database with name ${result.data.name} and influenceur ${result.data.restoAlreadyInDatabase}`,
                });
                return;
            }

            if (result.error) {
                const description = result.error.includes('closed') 
                    ? `The restaurant with name ${row.name} is closed with status ${result.error}`
                    : result.error;
                    
                toast.error('An error occured', { description });
                return;
            }

            const updatedData = data.map((item, i) =>
                i === index ? { ...item, validated_at: new Date().toISOString() } : item
            );
            setData(updatedData);
            
            toast.success('The record has been sent to the database successfully.', {
                description: `The record with name ${row.name} has been registered`,
            });
        } catch (error) {
            console.error('Error:', error);
            toast.warning(`An error occurred`, {
                description: `The record could not be registered because of : ${error}`,
            });
        }
    };

    const handleValidationWithoutAdding = async (index: number) => {
        const row = data[index];
        try {
            toast.info('Ongoing', {
                description: `The record with name ${row.name} is being marked as validated without being registered`,
            });

            const result = await validatorApi.validateWithoutAdding(row.id!);

            if (result.success) {
                const updatedData = data.map((item, i) =>
                    i === index ? { ...item, validated_at: new Date().toISOString() } : item
                );
                setData(updatedData);
                
                toast.success('The record has been validated successfully.', {
                    description: `The record with name ${row.name} has been validated`,
                });
            }
        } catch (error) {
            console.error('Error:', error);
            toast.warning(`An error occurred`, {
                description: `The record could not be validated because of : ${error}`,
            });
        }
    };

    return {
        handleGoogleSearch,
        handleValidation,
        handleValidationWithoutAdding,
    };
};
