import type { ScrappedDataType } from "../types";

export const useDataManagement = (data: ScrappedDataType[], setData: (data: ScrappedDataType[]) => void) => {
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, 
        index: number, 
        field: keyof ScrappedDataType
    ) => {
        const newData = [...data];
        newData[index] = {
            ...newData[index],
            [field]: e.target.value
        };
        setData(newData);
    };

    const handleCheckboxChange = (
        e: React.ChangeEvent<HTMLInputElement>, 
        index: number, 
        field: keyof ScrappedDataType
    ) => {
        const newData = [...data];
        newData[index] = {
            ...newData[index],
            [field]: e.target.checked
        };
        setData(newData);
    };

    const handleDuplicate = (index: number) => {
        const newRow = { ...data[index], created_at: new Date().toISOString() };
        const newData = [...data];
        newData.splice(index + 1, 0, newRow);
        setData(newData);
    };

    const handleAddRow = () => {
        const newRow: ScrappedDataType = {
            vlog_date: '',
            vlog_link: '',
            vlog_title: '',
            influencer_name: '',
            name: '',
            full_caption: '',
            instagram_link: '',
            google_name: '',
            address: '',
            latitude: undefined,
            longitude: undefined,
            places_id: '',
            places_rating: undefined,
            places_user_ratings_total: undefined,
            validated_at: '',
            addVlogOnExistingPlace: false,
        };
        setData([...data, newRow]);
    };

    return {
        handleInputChange,
        handleCheckboxChange,
        handleDuplicate,
        handleAddRow,
    };
};
