import { Toaster } from 'sonner';
import AddIcon from '@mui/icons-material/Add';
import { ValidatorTable } from '../components/ValidatorTable';
import { useValidatorData } from '../hooks/useValidatorData';
import { useValidatorActions } from '../hooks/useValidatorActions';
import { useDataManagement } from '../hooks/useDataManagement';

const Validator = () => {

    const { data, setData } = useValidatorData();
    const { handleGoogleSearch, handleValidation, handleValidationWithoutAdding } = useValidatorActions(data, setData);
    const { handleInputChange, handleCheckboxChange, handleDuplicate, handleAddRow } = useDataManagement(data, setData);

    return (
        <>
            <Toaster position="top-right" richColors duration={2000} />
            <div className="max-w-8xl mx-auto p-2 rounded-lg">
                <h1 className="uppercase text-center text-xl font-medium py-2 ">
                    Jaifaim Validator
                </h1>
                <ValidatorTable
                    data={data}
                    onInputChange={handleInputChange}
                    onCheckboxChange={handleCheckboxChange}
                    onDuplicate={handleDuplicate}
                    onGoogleSearch={handleGoogleSearch}
                    onValidate={handleValidation}
                    onValidateWithoutAdding={handleValidationWithoutAdding}
                />
                <div className="flex justify-center mt-4">
                    <button
                        onClick={handleAddRow}
                        className="px-2 py-1 text-white bg-green-500 rounded-full"
                    >
                        <AddIcon />
                    </button>
                </div>
            </div>
        </>
    );
}

export default Validator;