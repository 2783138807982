import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';

import supabase from './services/supabaseClient';
import { useEffect, useState } from 'react';
import Validator from './pages/Validator';

enum USER_STATUS {
    SIGNED_IN,
    SIGNED_OUT,
}

const App = () => {
    const [userStatus, setUserStatus] = useState<USER_STATUS>();

    useEffect(() => {
        supabase.auth.onAuthStateChange((event, session) => {
            switch (event) {
                case 'SIGNED_IN':
                    setUserStatus(USER_STATUS.SIGNED_IN);
                    break;
                case 'SIGNED_OUT':
                    setUserStatus(USER_STATUS.SIGNED_OUT);
                    break;
                default:
            }
        });
    }, []);

    return userStatus === USER_STATUS.SIGNED_IN ? (
        <Validator />
    ) : (
        <Auth
            supabaseClient={supabase}
            appearance={{
                theme: ThemeSupa,
                style: {
                  container: {
                    width: '70%',
                    margin: 'auto',
                    padding: '2rem',
                    backgroundColor: '#fff',
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '500px',
                  },
                  label: {
                    fontSize: '16px',
                    color: '#333',
                    marginBottom: '0.5rem',
                  },
                  input: {
                    fontSize: '16px',
                    padding: '0.75rem',
                    marginBottom: '1rem',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    color: 'black',
                    backgroundColor: '#ffff',
                    width: '100%',
                  },
                  button: {
                    fontSize: '16px',
                    padding: '0.75rem 1.5rem',
                    color: '#fff',
                    backgroundColor: '#3498db',
                    border: 'none',
                    borderRadius: '4px',
                    maxWidth: '250px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                  }
                },
              }}
            localization={{
                variables: {
                    sign_in: {
                        email_label: 'Email',
                        email_input_placeholder: 'Email',
                        password_label: 'Mot de passe',
                        password_input_placeholder: 'Mot de passe',
                        button_label: 'Se connecter',
                        loading_button_label: 'Connexion ...',
                    },
                    sign_up: {
                        link_text: '',
                    },
                    forgotten_password: {
                        link_text: '',
                    }
                },
            }}
            theme="dark"
            providers={[]}
        />
    );
};

export default App;
