export const TableHeader = () => (
    <thead className="bg-gray-100">
        <tr>
            <th
                className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                style={{ width: '150px' }}
            >
                VLOG_DATE
            </th>
            <th
                className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                style={{ width: '500px' }}
            >
                VLOG_LINK
            </th>
            <th
                className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                style={{ width: '400px' }}
            >
                VLOG_TITLE
            </th>
            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                INFLUENCER_NAME
            </th>
            <th
                className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                style={{ width: '400px' }}
            >
                FULL_CAPTION
            </th>
            <th
                className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                style={{ width: '500px' }}
            >
                INSTAGRAM_LINK
            </th>
            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                SCRAPED_NAME
            </th>
            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                ACTIONS
            </th>
            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                DUPLICATE
            </th>
        </tr>
        <tr>
            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                FOOD_TYPE
            </th>

            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                LATITUDE
            </th>
            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                LONGITUDE
            </th>

            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                PLACES_RATING
            </th>
            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                PLACES_RATINGS_TOTAL
            </th>
            <th
                className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                style={{ width: '250px' }}
            >
                ADDRESS
            </th>
            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                GOOGLE_NAME
            </th>
            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                ADD VLOG ONLY
            </th>
            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                VALIDATE
            </th>
        </tr>
    </thead>
);