import React from 'react';
import type { ScrappedDataType } from '../types';
import { food_types } from '../constants';

interface TableRowProps {
    row: ScrappedDataType;
    index: number;
    onInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, index: number, field: keyof ScrappedDataType) => void;
    onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, index: number, field: keyof ScrappedDataType) => void;
    onDuplicate: (index: number) => void;
    onGoogleSearch: (index: number) => void;
    onValidate: (index: number) => void;
    onValidateWithoutAdding: (index: number) => void;
}

export const TableRow: React.FC<TableRowProps> = ({
    row,
    index,
    onInputChange,
    onCheckboxChange,
    onDuplicate,
    onGoogleSearch,
    onValidate,
    onValidateWithoutAdding,
}) => (
    <React.Fragment key={index}>
                                <tr>
                                    <td className="px-2 py-1 whitespace-nowrap">
                                        <input
                                            type="text"
                                            value={row.vlog_date}
                                            onChange={(e) =>
                                                onInputChange(
                                                    e,
                                                    index,
                                                    'vlog_date'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td
                                        className="px-2 py-1 whitespace-nowrap"
                                        style={{ width: '250px' }}
                                    >
                                        <input
                                            type="text"
                                            value={row.vlog_link}
                                            onChange={(e) =>
                                                onInputChange(
                                                    e,
                                                    index,
                                                    'vlog_link'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap">
                                        <input
                                            type="text"
                                            value={row.vlog_title}
                                            onChange={(e) =>
                                                onInputChange(
                                                    e,
                                                    index,
                                                    'vlog_title'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap">
                                        <input
                                            type="text"
                                            value={row.influencer_name}
                                            onChange={(e) =>
                                                onInputChange(
                                                    e,
                                                    index,
                                                    'influencer_name'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>

                                    <td
                                        className="px-2 py-1 whitespace-nowrap"
                                        style={{ width: '300px' }}
                                    >
                                        <textarea
                                            value={row.full_caption}
                                            onChange={(e) =>
                                                onInputChange(
                                                    e,
                                                    index,
                                                    'full_caption'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md h-60"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td
                                        className="px-2 py-1 whitespace-nowrap"
                                        style={{ width: '250px' }}
                                    >
                                        <input
                                            type="text"
                                            value={row.instagram_link}
                                            onChange={(e) =>
                                                onInputChange(
                                                    e,
                                                    index,
                                                    'instagram_link'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap">
                                        <input
                                            type="text"
                                            value={row.name}
                                            onChange={(e) =>
                                                onInputChange(
                                                    e,
                                                    index,
                                                    'name'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap text-right text-sm font-medium">
                                        <button
                                            onClick={() =>
                                                onGoogleSearch(index)
                                            }
                                            className="px-3 py-1 bg-blue-500 text-white text-xs rounded-md hover:bg-blue-600"
                                        >
                                            Search in Google
                                        </button>
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap text-right text-sm font-medium">
                                        <button
                                            onClick={() =>
                                                onDuplicate(index)
                                            }
                                            className="px-2 py-1 text-xs bg-orange-500 text-white rounded-md"
                                        >
                                            Duplicate
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        className="px-2 py-1 whitespace-nowrap"
                                        style={{ width: '250px' }}
                                    >
                                        <select
                                            value={row.food_type}
                                            onChange={(e) =>
                                                onInputChange(
                                                    e,
                                                    index,
                                                    'food_type'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        >
                                            {food_types.map((food_type) => (
                                                <option
                                                    key={food_type}
                                                    value={food_type}
                                                >
                                                    {food_type}
                                                </option>
                                            ))}
                                        </select>
                                    </td>

                                    <td className="px-2 py-1 whitespace-nowrap">
                                        <input
                                            type="text"
                                            value={row.latitude}
                                            onChange={(e) =>
                                                onInputChange(
                                                    e,
                                                    index,
                                                    'latitude'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap">
                                        <input
                                            type="text"
                                            value={row.longitude}
                                            onChange={(e) =>
                                                onInputChange(
                                                    e,
                                                    index,
                                                    'longitude'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>

                                    <td className="px-2 py-1 whitespace-nowrap">
                                        <input
                                            type="text"
                                            value={row.places_rating}
                                            onChange={(e) =>
                                                onInputChange(
                                                    e,
                                                    index,
                                                    'places_rating'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap">
                                        <input
                                            type="text"
                                            value={
                                                row.places_user_ratings_total
                                            }
                                            onChange={(e) =>
                                                onInputChange(
                                                    e,
                                                    index,
                                                    'places_user_ratings_total'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td
                                        className="px-2 py-1 whitespace-nowrap"
                                        style={{ width: '250px' }}
                                    >
                                        <input
                                            type="text"
                                            value={row.address}
                                            onChange={(e) =>
                                                onInputChange(
                                                    e,
                                                    index,
                                                    'address'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap">
                                        <input
                                            type="text"
                                            value={row.google_name}
                                            onChange={(e) =>
                                                onInputChange(
                                                    e,
                                                    index,
                                                    'google_name'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap text-right text-sm font-medium">
                                        <button
                                            onClick={() =>
                                                onValidateWithoutAdding(
                                                    index
                                                )
                                            }
                                            className={`px-2 py-1 text-xs rounded-md ${row.validated_at ? 'bg-gray-400 text-gray-100 cursor-not-allowed' : 'bg-red-500 text-white'}`}
                                            disabled={!!row.validated_at}
                                        >
                                            Don't save
                                        </button>
                                        <input
                                            type="checkbox"
                                            checked={row.addVlogOnExistingPlace}
                                            onChange={(e) =>
                                                onCheckboxChange(
                                                    e,
                                                    index,
                                                    'addVlogOnExistingPlace'
                                                )
                                            }
                                            className="form-checkbox mx-4"
                                        />
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap text-right text-sm font-medium">
                                        <button
                                            onClick={() =>
                                                onValidate(index)
                                            }
                                            className={`px-2 py-1 text-xs rounded-md ${row.validated_at ? 'bg-gray-400 text-gray-100 cursor-not-allowed' : 'bg-green-500 text-white'}`}
                                            disabled={!!row.validated_at}
                                        >
                                            Save
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={9}>
                                        <div className="my-3">
                                            <hr className="border-t-4 border-gray-300" />
                                        </div>
                                    </td>
                                </tr>
                            </React.Fragment>
);
