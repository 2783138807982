import { useState, useEffect } from 'react';
import { API_BASE_URL } from '../constants';
import { formatDate } from '../utils';
import type { ScrappedDataType } from '../types';

export const useValidatorData = () => {
    const [data, setData] = useState<ScrappedDataType[]>([]);

    useEffect(() => {
        fetchValidatorData();
    }, []);

    const fetchValidatorData = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/scrapedData`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            const result = await response.json();
            const formattedData = result.data.map((item: ScrappedDataType) => ({
                ...item,
                addVlogOnExistingPlace: false,
                vlog_date: formatDate(item.vlog_date),
            }));
            setData(formattedData);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return { data, setData };
};
